import React, { useState } from 'react'
import styled from 'styled-components'

import CountryCard from '../elements/cards/CountryCard'
import MapChart from './MapChart'
import useWindowSize from '../../utils/hooks/useWindowSize'

const Container = styled.div`
  box-shadow: inset 0px -4px 4px -5px black;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 1rem 2rem 1rem;
  @media (min-width: 480px) {
    padding: 0 1.5rem 3rem 1.5rem;
  }
  @media (min-width: 768px) {
    padding: 0 2rem 3rem 2rem;
  }
`

const CardList = styled.ul`
  align-items: flex-start;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 1rem;
  height: 100%;
  justify-content: center;
  list-style: none;
  margin: 0 auto;
  padding-right: 1rem;
  width: 100%;
  @media (min-width: 480px) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
  @media (min-width: 980px) {
    width: 50%;
  }
`

const MapPanel = styled.div`
  justify-content: center;
  margin: 0 auto auto auto;
  width: 50%;
`
interface Props {
  activeCountries: any
}

const Countries = React.memo(function ({ activeCountries }: Props): JSX.Element {
  Countries.displayName = 'Countries'
  const countryList = activeCountries?.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name))
  const [active, setActive] = useState(false)
  const size = useWindowSize()

  const onMouseEnter = (geo: any): void => {
    setActive(geo)
  }

  const onMouseLeave = (): void => {
    setActive(false)
  }

  return (
    <Container>
      <CardList key={JSON.stringify(countryList)}>
        {countryList?.map((country: any) => (
          <CountryCard
            key={country.name}
            alt={country.image_alt}
            country={country}
            image={country.image}
            isActive={active === country.name}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        ))}
      </CardList>
      {size.width > 980 && (
        <MapPanel>
          <MapChart
            active={active}
            activeContinent={activeCountries[0]?.continent}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </MapPanel>
      )}
    </Container>
  )
})

export default Countries

/*
  const mapData = activeContinentData?.map
  {(Boolean(mapData)) && mapData !== null &&
    <MapPanel key={JSON.stringify(mapData)}>
      <MapChart data={mapData} active={active} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
    </MapPanel>
  }
*/
