import * as React from 'react'
import styled from 'styled-components'
import { type IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import CardContainer from '../../common/CardContainer'
import ImageCardTitle from '../../common/text/ImageCardTitle'
import CoverImage from '../../common/CardImage'

const Card = styled(CardContainer)`
  background-color: var(--color-secondary-900);
  height: 8rem;
  min-width: 10rem;
  transition: transform 100ms ease-in-out;
  -webkit-tap-highlight-color: transparent;
  will-change: transform;
  &:active {
    transform: scale(0.95);
  }
  @media (min-width: 480px) {
    height: 10rem;
    min-width: 15rem;
    &:hover {
      ${CoverImage} {
        filter: brightness(70%);
        transform: scale(1.05);
      }
    }
    &:active {
      transform: scale(0.97);
    }
  }
`

const ImageOverlay = styled.div`
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.5) 0rem -4rem 1.5rem -1rem inset;
  height: 100%;
  position: absolute;
  transition: box-shadow 300ms ease-in-out;
  width: 100%;
  @media (min-width: 480px) {
    box-shadow: rgba(0, 0, 0, 0.6) 0rem -5rem 1.5rem -1.5rem inset;
  }
`

const Title = styled(ImageCardTitle)`
  padding: 1rem 0.5rem;
  @media (min-width: 480px) {
    padding: 1.5rem 0.5rem;
    ${Card}:hover & {
      transform: translate(-50%, -50%);
    }
  }
`

interface Props {
  alt: string
  country: any
  image: IGatsbyImageData
  isActive: boolean
  onMouseEnter: any
  onMouseLeave: any
}

export default function CountryCard({ alt, country, image, isActive, onMouseEnter, onMouseLeave }: Props): JSX.Element {
  const countrySlug = country.name.toLowerCase().replace(/ /g, '-')
  const coontinentSlug = country.continent.toLowerCase().replace(/ /g, '-')
  const countryName = country.name === 'UK' ? 'United Kingdom' : country.name === 'USA' ? 'United States' : country.name

  return (
    <Card
      key={countrySlug}
      to={`/${coontinentSlug}/${countrySlug}`}
      role="button"
      tabIndex={0}
      onMouseEnter={() => onMouseEnter(countryName)}
      onMouseLeave={() => onMouseLeave()}
    >
      <CoverImage alt={alt} image={getImage(image) as IGatsbyImageData} loading="lazy" />
      <ImageOverlay>
        <Title>{countryName}</Title>
      </ImageOverlay>
    </Card>
  )
}
