import styled from 'styled-components'

const ImageCardTitle = styled.h1`
  bottom: 0;
  color: whitesmoke;
  font: 700 var(--fontSize-3) / 1 var(--font-montserrat);
  left: 50%;
  letter-spacing: var(--letterSpacing-3);
  margin: auto;
  padding: 2rem 0.5rem;
  position: absolute;
  text-align: center;
  text-shadow:
    0px 4px 3px rgba(0, 0, 0, 0.5),
    0px 8px 13px rgba(0, 0, 0, 0.2),
    0px 18px 23px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, 0%);
  width: 100%;
  will-change: transform;
  @media (min-width: 480px) {
    font-size: var(--fontSize-4);
    transition: transform 500ms ease-in-out;
    will-change: transform;
  }
`

export default ImageCardTitle
