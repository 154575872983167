import React, { useEffect, useState } from 'react'
import { ComposableMap, Geographies, Geography, Graticule } from 'react-simple-maps'
import styled from 'styled-components'
import map from '../../data/MapData'

const StyledGraticule = styled(Graticule)`
  stroke: ${props => props.theme.greyscale600to400};
`

const StyledGeography = styled(Geography)<{ $isActive: boolean }>`
  fill: ${({ $isActive }) => ($isActive ? '#3AAFA9' : '#2B7A78')};
  transition: fill 300ms ease-in-out;
`

interface Props {
  active: boolean
  activeContinent: string
  onMouseEnter: (geo: any) => void
  onMouseLeave: () => void
}

export default function MapChart({ active, activeContinent, onMouseEnter, onMouseLeave }: Props): JSX.Element {
  const [mapPosition, setMapPosition] = useState({ rotate: [0, 0, 0], scale: 1 })

  useEffect(() => {
    let mapConfig = { rotate: [0, 0, 0], scale: 1 }

    switch (activeContinent) {
      case 'Europe':
        mapConfig = { rotate: [-15, -54, 0], scale: 950 }
        break
      case 'Africa':
        mapConfig = { rotate: [0, 20, 0], scale: 1.2 }
        break
      case 'North America':
        mapConfig = { rotate: [100, -45, 0], scale: 490 }
        break
      case 'South America':
        mapConfig = { rotate: [65, 20, 0], scale: 500 }
        break
      case 'Asia':
        mapConfig = { rotate: [-90, -30, 0], scale: 500 }
        break
      case 'Australia':
        mapConfig = { rotate: [140, 0, 0], scale: 500 }
        break
      default:
        break
    }

    setMapPosition(mapConfig)
  }, [activeContinent])

  return (
    <ComposableMap projection="geoOrthographic" projectionConfig={mapPosition} style={{ paddingLeft: '1rem' }}>
      <StyledGraticule />
      <Geographies geography={map}>
        {({ geographies }: { geographies: any }) =>
          geographies.map((geo: any) => (
            <StyledGeography
              key={geo.rsmKey}
              $isActive={active === geo.properties.name}
              geography={geo}
              stroke="#000000"
              onMouseEnter={() => {
                onMouseEnter(geo.properties.name)
              }}
              onMouseLeave={() => {
                onMouseLeave()
              }}
              style={{
                transition: 'fill 500ms ease-in-out',
                hover: {
                  fill: '#3AAFA9',
                },
              }}
            />
          ))
        }
      </Geographies>
    </ComposableMap>
  )
}
