import React, { lazy, useState, useRef } from 'react'
import { Link, graphql, withPrefix } from 'gatsby'
import styled, { css } from 'styled-components'

import useWindowSize from '../utils/hooks/useWindowSize'
import { useOnClickOutside } from '../utils/hooks/useOnClickOutside'
import addSuspense from '../utils/AddSuspense'

import Bio from '../components/elements/bio'
import ButtonArrow from '../components/elements/buttons/ButtonArrow'
import Layout from '../components/layouts/layout'
import Seo from '../components/seo'
import Countries from '../components/sections/Countries'
import DestHeader from '../components/sections/headers/DestHeader'
import { slideUp } from '../styles/Keyframes'

const EventHighlight = lazy(async () => await import('../components/sections/EventsWrapper'))
const CarouselSection = lazy(async () => await import('../components/sections/CarouselSection'))

const ContinentMenu = styled.div`
  background-color: ${props => props.theme.greyscale100secondary800};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 2.5rem;
  overflow: hidden;
  position: sticky;
  top: 0;
  width: 100%;
  @media (min-width: 980px) {
    margin-top: 4rem;
  }
`

const ButtonStyle = css`
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  letter-spacing: var(--letterSpacing-0);
  margin: auto;
  user-select: none;
`

const DropdownButton = styled.button`
  ${ButtonStyle}
  background-color: transparent;
  color: ${props => props.theme.black300greyscale200};
  font: 400 var(--fontSize-1) / 1.2 var(--font-montserrat);
  height: 100%;
  padding: 0 1rem;
  width: 100%;
`

const ContinentButton = styled.button<{ $index: number; $isActive: boolean; $open: boolean }>`
  ${ButtonStyle}
  background: linear-gradient(currentColor 0 0) bottom / var(--d, 0) 3px no-repeat;
  color: ${props => props.theme.black300greyscale200};
  font: 400 var(--fontSize-1) / 1.2 var(--font-montserrat);
  font-weight: ${({ $isActive }) => ($isActive ? '600' : '400')};
  max-width: 15rem;
  padding: 0.5rem 1rem;
  background-size: var(--d, 0) 3px;
  transition: background-size 500ms ease-in-out;
  width: 60%;
  --d: ${({ $isActive }) => ($isActive ? '100%' : '0%')};
  @media (max-width: 480px) {
    animation: ${slideUp} 0.4s cubic-bezier(0, 0, 0.6, 1.5) forwards;
    animation-delay: ${({ $index }) => `${$index * 0.15 + 0.25}s`};
    display: ${({ $open }) => ($open ? 'none' : 'flex')};
    opacity: 0;
  }
  @media (min-width: 480px) {
    &:focus,
    &:hover {
      --d: 100%;
    }
    height: 100%;
    margin: auto 0.25rem;
    opacity: 1;
    padding: 0 0.5rem;
    visibility: visible;
    width: auto;
  }
  @media (min-width: 768px) {
    margin: auto 0.75rem;
  }
`

const DropdownPanel = styled.div<{ $open: boolean }>`
  background-color: ${props => props.theme.greyscale300secondary900};
  border: none;
  box-shadow:
    inset 0px 4px 4px -5px black,
    inset 0px -4px 4px -5px black;
  height: ${({ $open }) => ($open ? '0' : '11rem')};
  display: flex;
  flex-direction: column;
  top: 7rem;
  right: 0;
  transition:
    height 350ms ease-out,
    background-color 300ms ease-in-out;
  width: 100%;
  z-index: 10;
`

const Texture = styled.div`
  background-image: url(${withPrefix('/images/tilebg.jpg')});
  background-repeat: repeat;
  box-sizing: border-box;
`

const Overlay = styled.div<{ $open: boolean }>`
  background-color: ${props => props.theme.greyscale200secondary900TEX};
  box-shadow: ${({ $open }) => ($open ? 'inset 0px 4px 4px -5px black' : 'none')};
  transition:
    background-color 300ms ease-in-out,
    box-shadow 500ms ease-in-out;
`

const ContentContainer = styled.div`
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`

interface Props {
  data: any
  location: any
}

export default function continentsPage({ data, location }: Props): JSX.Element {
  const ref = useRef(null)
  const size = useWindowSize()
  const [dropdown, setDropdown] = useState(true)
  const { nodes } = data.allMarkdownRemark
  const activeContinent = data.allDataLocationsJson.nodes[0]
  const menuContinents = ['Asia', 'Europe', 'North America', 'South America']
  const routes = nodes.filter((node: any) => node.frontmatter.type.toLowerCase() === 'route')
  const posts = nodes.filter((node: any) => node.frontmatter.type.toLowerCase() === 'article')
  const events = nodes.filter((node: any) => node.frontmatter.type.toLowerCase() === 'event')

  if (activeContinent === null) {
    return (
      <Layout location={location}>
        <Bio />
        <p>Error. No destinations found.</p>
      </Layout>
    )
  }

  useOnClickOutside(ref, () => {
    setDropdown(true)
  })

  const toggleDropdown = (): void => {
    setDropdown(prev => !prev)
  }

  const menuItems = menuContinents.map((continent: any, index: number) => (
    <Link
      key={continent}
      tabIndex={-1}
      to={`/${continent.toLowerCase().replace(/ /g, '-')}`}
      style={{ textDecoration: 'none' }}
    >
      <ContinentButton
        $index={index}
        tabIndex={size.width < 480 ? (dropdown ? -1 : 0) : 0}
        $isActive={continent === activeContinent.title}
        $open={dropdown}
      >
        {continent}
      </ContinentButton>
    </Link>
  ))

  const getDropdownButton = (): JSX.Element => (
    <DropdownButton role="button" aria-expanded={dropdown} onClick={toggleDropdown}>
      Select Continent
      <ButtonArrow flip={!dropdown} />
    </DropdownButton>
  )

  return (
    <Layout isDropdownOpen={dropdown} location={location}>
      <ContinentMenu ref={ref}>{size.width >= 480 ? menuItems : getDropdownButton()}</ContinentMenu>
      {size.width < 480 && (
        <DropdownPanel $open={dropdown}>
          <div style={{ margin: 'auto 0' }}>{menuItems}</div>
        </DropdownPanel>
      )}
      <Texture>
        <Overlay $open={dropdown}>
          <DestHeader title={activeContinent?.title} description={activeContinent?.description} continent />
          <Countries activeCountries={activeContinent.countries} />
        </Overlay>
      </Texture>
      <ContentContainer>
        {Boolean(events) && events.length !== 0 && addSuspense(<EventHighlight posts={events} />)}
        {Boolean(posts) && posts.length !== 0 && addSuspense(<CarouselSection type={'ARTICLE'} posts={posts} />)}
        {Boolean(routes) && routes.length !== 0 && addSuspense(<CarouselSection type={'ROUTE'} posts={routes} />)}
      </ContentContainer>
    </Layout>
  )
}

export function Head({ location }: any): JSX.Element {
  return (
    <Seo title="Continents" location={location} description={'Find the best locations around the world by continent'} />
  )
}

export const pageQuery = graphql`
  query destinationsQuery($continent: String) {
    site {
      siteMetadata {
        title
      }
    }
    allDataLocationsJson(filter: { title: { eq: $continent } }) {
      nodes {
        id
        title
        description
        countries {
          name
          continent
          image_alt
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 800, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        map {
          url
          rotate
          scale
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { continent: { eq: $continent } } }
      sort: { frontmatter: { date: DESC } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          startdate(formatString: "MMMM DD, YYYY")
          enddate(formatString: "MMMM DD, YYYY")
          title
          aka
          description
          type
          subtype
          duration
          city
          country
          countries
          continent
          hero_image_credit_text
          hero_image_credit_link
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 700, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
